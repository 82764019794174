import * as React from 'react';
import {useAuth} from '../Auth/AuthContext';
// @ts-ignore
import CriblIcon from '../../static/svgs/cribl-logo.svg';
// @ts-ignore
import {resendEmailVerification} from '../../services/API';
import '../../styles/App.less';
import {navigate} from 'gatsby';
import {Button, Card, message, Result, Spin} from 'antd';
import {useQueryParam} from 'gatsby-query-params';
import cx from 'classnames';
// @ts-ignore
import * as sharedStyles from '../../styles/shared.module.less';

export const Verify = () => {
  const {user, authToken, isLoading, getAccessTokenSilently} = useAuth();
  const [resent, setResent] = React.useState(false);
  const queryMessage = useQueryParam('message');
  const success = useQueryParam('success');

  const resendEmail = async () => {
    setResent(true);
    try {
      await resendEmailVerification(user.sub, authToken);
    } catch (e) {
      message.error(e.message || 'Unable to send verification email.  Please try again later.');
    }
    // reset link after 10 seconds
    setTimeout(() => setResent(false), 10000);
  };

  React.useEffect(() => {
    if (isLoading) return;
    if (!user?.email_verified) {
      (async () => {
        // double check user for email_verified flag with auth0 server (ignore cache)
        await getAccessTokenSilently({ignoreCache: true});
        if (user?.email_verified) {
          await navigate('/organizations');
        }
      })();
    }
    if (user?.email_verified || (success && user?.sub)) {
      void navigate('/organizations');
    }
  }, [isLoading, success]);

  return (
    <Card className={cx('main-card-auth0')}>
      <div className={sharedStyles.iconContainerAuth0}>
        <CriblIcon style={{height: 40}} />
      </div>
      {queryMessage && (
        <>
          <Result status={success === 'false' ? 'error' : 'success'} title={queryMessage} />
          {success === 'true' && (
            <Button type={'primary'} size={'large'} onClick={() => void navigate('/organizations')}>
              Continue
            </Button>
          )}
        </>
      )}
      {!queryMessage && isLoading && <Spin />}
      {!queryMessage && !isLoading && (
        <>
          <h1>Verify your account</h1>
          <p>
            A email has been sent to
            <br />
            {user?.email}
          </p>
          <p>Please check your email to complete account verification.</p>
          <p>Haven't got the email? {resent ? <>Email resent!</> : <a onClick={resendEmail}>Resend email</a>}</p>
        </>
      )}
    </Card>
  );
};
