import * as React from 'react';
import '../styles/App.less';
import {Helmet} from 'react-helmet';
import {Verify} from '../components/Verify/Verify';

// markup
const IndexPage = (props: any) => {
  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <title>Cribl.Cloud - Verify your account</title>
        <link rel='canonical' href='https://portal.cribl.cloud/verify' />
      </Helmet>
      <main key={'verify'} className={'verify-holder-auth0'}>
        <Verify {...props} />
      </main>
    </>
  );
};

export default IndexPage;
